<template>
  <v-container
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_mobile) + ')',
      height: '100vh',
      width: '100vw',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div class="justify-center">
      <div class="d-flex flex-column align-center">
        <v-btn
          @click="SaveAndContinue"
          :color="WEBAPPPRO_BUTTON_COLOR_1"
          :style="{
            color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
            boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
            borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
            textTransform: 'none',
            marginBottom: '20px',
            padding: '25px 100px',
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
            fontWeight: 'bold',
            lineHeight: '18px',
            letterSpacing: '0em',
            textAlign: 'center',
            width: '327px',
            height: '52px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '300px',
          }"
        >
          Traitement plus rapide <br />Code ephoto disponible dans 15 mns
        </v-btn>
      </div>
    </div>
    <div class="camera-shoot justify-center d-flex my-5 align-center">
      <v-btn
        @click="takePhoto"
        :color="WEBAPPPRO_BUTTON_COLOR_2"
        class="centered-button mt-2"
        :style="{
          color: WEBAPPPRO_BUTTON_TEXT_COLOR_2,
          boxShadow: WEBAPPPRO_BUTTON_SHADOW_2,
          borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
          'text-transform': 'none',
          padding: '25px 100px',
          fontFamily: WEBAPPPRO_FONT_FAMILY_3,
          fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
          'font-weight': 'bold',
          'line-height': '18px',
          'letter-spacing': '0em',
          'text-align': 'center',
          marginTop: '10px',
          height: '52px',
          width: '327px',
        }"
      >
        Reprendre ma photo
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'Choice',
  data() {
    return {
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_2',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      fontSize2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      fontSize3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      borderRound: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
      WEBAPPPRO_ICON_COLOR_INFO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO_2',
      ),
      WEBAPPPRO_ICON_COLOR_WARNING_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_WARNING_2',
      ),
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      validatedImages: localStorageService.getValue('validatedImages'),
      addOffer: localStorageService.getValue('addOfferId'),
    }
  },
  methods: {
    takePhoto() {
      this.$router.push({ name: 'ShootPhoto' })
    },
    SaveAndContinue() {
      if (this.validatedImages.length > 0 && this.addOffer != null) {
        this.$router.push({
          name: 'VerifyUser',
        })
      } else {
        alert("Vous n'avez pas de photos disponibles")
      }
    },
  },
  mounted() {},
}
</script>
