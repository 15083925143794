var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_desktop) + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"accueil-drawer",attrs:{"rail":_vm.rail,"permanent":""}},[_c('Accueil')],1),_c('div',{staticClass:"\n      d-flex\n      flex-column\n      justify-center\n      align-center\n      photo-capture-container\n    "},[_c('div',{staticClass:"main-content-wrapper"},[_c('div',{staticClass:"photo-status"},[_c('h2',{staticClass:"text-center",style:({
            color: _vm.WEBAPPPRO_TEXT_COLOR_4,
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_2,
            fontSize: _vm.WEBAPPPRO_FONT_SIZE_2,
            fontWeight: 700,
            marginTop: '10px',
          })},[_vm._v(" Suivi de commande ")]),_c('p',{staticClass:"text-center",style:({
            // color: WEBAPPPRO_TEXT_COLOR_2,
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
            fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
            fontWeight: 400,
            marginTop: '10px',
          })},[_vm._v(" Suivez l’état chaque commande lorsque que le candidat a pris sa photo. ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-card',{staticClass:"pa-6",style:({
              width: '100%',
              borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
            })},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items.map(function (item) { return (Object.assign({}, item,
                  {createdAt: _vm.formatCreatedAt(item.createdAt)})); }),"items-per-page":10},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getStatusSvg(item.status))}})]}},{key:"item.id",fn:function(ref){
                var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.firstName",fn:function(ref){
                  var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.firstName)+" ")])]}},{key:"item.lastName",fn:function(ref){
                  var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.lastName)+" ")])]}},{key:"item.email",fn:function(ref){
                  var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.codeEPhoto",fn:function(ref){
                  var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.codeEPhoto)+" ")])]}},{key:"item.method",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(_vm.getPaymentMethod(item))+" ")])]}},{key:"item.createdAt",fn:function(ref){
                  var item = ref.item;
return [_c('td',{style:({
                    fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 400,
                  })},[_vm._v(" "+_vm._s(item.createdAt)+" ")])]}}],null,true)})],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }