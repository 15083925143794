<template>
  <v-container
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/auto.png') + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'stretch',
      overflow: 'auto',
      backgroundSize: '57% 100%',
      backgroundPosition: 'right center',
    }"
    class="background-container"
  >
    <v-row>
      <v-card
        class="d-flex align-center justify-center"
        :style="{
          borderRadius: WEBAPPPRO_CARD_HOME_BORDER_ROUND,
          width: '45%',
          height: 'auto',
          backgroundColor: WEBAPPPRO_COLOR_CARD_HOME,
        }"
      >
        <div>
          <v-img
            v-if="WEBAPPPRO_LOGO_2"
            :src="require(`../../assets/images/${WEBAPPPRO_LOGO_2}`)"
            width="179px"
            class="mx-auto mb-10"
          ></v-img>
          <h2
            class="text-center"
            :style="{
              color: WEBAPPPRO_TEXT_COLOR_2,
              fontFamily: WEBAPPPRO_FONT_FAMILY_2,
              fontSize: WEBAPPPRO_FONT_SIZE_2,
              fontWeight: 700,
              marginTop: '10px',
            }"
            v-if="!stillLogged"
          >
            Connexion
          </h2>
          <v-alert type="error" v-if="errors.length">
            <ul>
              <li v-for="error in errors" :key="error.id">{{ error }}</li>
            </ul>
          </v-alert>
          <br />
          <div v-if="step === 1 && !stillLogged">
            <div style="display: flex; justify-content: center">
              <span
                :style="{
                  color: WEBAPPPRO_TEXT_COLOR_2,
                  fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  fontSize: WEBAPPPRO_FONT_SIZE_3,
                  fontWeight: 400,
                  marginTop: '10px',
                }"
                >Veuillez renseigner l'email de votre compte<br />pour recevoir
                le code de connexion.
              </span>
            </div>
            <div class="d-flex justify-center">
              <!--<v-icon left>mdi-email</v-icon> -->
              <!--<v-text-field label="Email" v-model="email" :outlined="false" :style="{ flex: '1', marginLeft: '5px' }"></v-text-field> -->
              <v-text-field
                density="dense"
                v-model="email"
                placeholder="Email"
                :style="{
                  borderRadius: '8px',
                  width: '400px',
                  height: '52px',
                  marginRight: '30px',
                  marginTop: '30px',
                }"
                solo
              >
                <template v-slot:prepend-inner>
                  <svg
                    class="ma-2"
                    width="17"
                    height="12"
                    viewBox="0 0 17 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.875 0H2.125C0.949167 0 0 1.005 0 2.25V9.75C0 10.995 0.949167 12 2.125 12H14.875C16.0508 12 17 10.995 17 9.75V2.25C17 1.005 16.0508 0 14.875 0ZM14.2092 1.5L8.5 5.13L2.79083 1.5H14.2092ZM14.875 10.5H2.125C1.72833 10.5 1.41667 10.17 1.41667 9.75V2.37L8.13167 6.645C8.35833 6.795 8.64167 6.795 8.85417 6.645L15.5692 2.37V9.75C15.5692 10.17 15.2575 10.5 14.8608 10.5H14.875Z"
                      fill="#C1C1C1"
                    />
                  </svg>
                </template>
              </v-text-field>
            </div>

            <div class="d-flex justify-center mt-5">
              <v-btn
                @click="submitEmail"
                :color="WEBAPPPRO_BUTTON_COLOR_1"
                :style="{
                  color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
                  boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
                  borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
                  textTransform: 'none',
                  marginBottom: '10px',
                  marginTop: '10px',
                  padding: '0px',
                  fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
                  fontWeight: 'bold',
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  width: '240px',
                  height: '52px',
                }"
                >Recevoir le code</v-btn
              >
            </div>
          </div>
          <div v-if="step === 2 && !stillLogged">
            <div style="display: flex; justify-content: center">
              <span
                :style="{
                  color: WEBAPPPRO_TEXT_COLOR_2,
                  fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  fontSize: WEBAPPPRO_FONT_SIZE_3,
                  fontWeight: 400,
                }"
                >Veuillez saisir le code à 6 chiffres reçu par email.
              </span>
            </div>
            <br />
            <!-- <v-text-field label="Code" v-model="code"></v-text-field>-->
            <div style="display: flex; justify-content: center">
              <input
                :style="{ color: WEBAPPPRO_INPUT_TEXT_COLOR }"
                type="text"
                @input="formatInput"
                placeholder="------"
                maxlength="6"
                v-model="code"
              />
            </div>
            <!-- <div class="d-flex justify-center">
              <v-text-field
                v-model="code"
                solo
                style="max-width: 200px"
              ></v-text-field>
            </div>-->
            <!--<v-progress-linear :value="(100 * timer) / 180" height="25" reverse>
        {{ formatTime(timer) }}
      </v-progress-linear> -->
            <div class="d-flex flex-column align-center justify-center mt-3">
              <v-btn
                @click="submitCode"
                :color="WEBAPPPRO_BUTTON_COLOR_1"
                :style="{
                  color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
                  boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
                  borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
                  textTransform: 'none',
                  marginBottom: '10px',
                  padding: '0px',
                  fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
                  fontWeight: 'bold',
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  width: '240px',
                  height: '52px',
                }"
                >Se Connecter</v-btn
              >
              <br />
              <a
                href="#"
                @click="newCode"
                :style="{
                  color: WEBAPPPRO_TEXT_COLOR_2,
                  textDecoration: 'underline',
                  fontWeight: '600',
                  fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  fontSize: WEBAPPPRO_FONT_SIZE_3,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                }"
                >{{ 'Renvoyer un nouveau code' }}</a
              >
            </div>
          </div>
        </div>
      </v-card>

      <!-- <v-col class="d-none d-lg-flex" lg="10"></v-col>-->
    </v-row>
  </v-container>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
export default {
  name: 'Authentication',
  data() {
    return {
      step: 1,
      email: '',
      code: '',
      authenticated: false,
      userEmail: '',
      timer: 180,
      timerCounter: null,
      errors: [],
      stillLogged: true,
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_CARD_HOME_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_HOME_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      WEBAPPPRO_COLOR_CARD_HOME: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_CARD_HOME',
      ),
      WEBAPPPRO_LOGO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_2',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_INPUT_TEXT_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_INPUT_TEXT_COLOR',
      ),
    }
  },
  methods: {
    formatInput() {
      let digits = this.inputValue.replace(/\D/g, '').slice(0, 6) // Remove non-digits and limit to 6 digits
      this.inputValue = digits.replace(/(\d{1,6})/g, '$1-').slice(0, 11) // Insert hyphens
      this.$emit('input', this.inputValue.replace(/-/g, '')) // Emit the raw value without hyphens
    },
    submitEmail() {
      // Simuler une vérification de l'email côté serveur
      // Vous devriez envoyer l'email au serveur pour validation réelle
      // et recevoir une réponse indiquant si l'email est valide.
      // Ici, nous simplifions en considérant l'email comme valide.

      //requets to receive code by email

      this.step = 2
      this.timer = 180
      this.timerCounter = setInterval(() => {
        if (this.timer > 0) {
          this.timer--
        } else {
          this.newCode()
        }
      }, 1000)

      requestService
        .post('/email-auth-request', { email: this.email })
        .then(() => {})
    },
    submitCode() {
      // Simuler une vérification du code côté serveur
      // Vous devriez envoyer le code au serveur pour validation réelle
      // et recevoir une réponse indiquant si le code est valide.
      // Ici, nous simplifions en considérant le code comme valide.
      //requets to verify the code
      requestService
        .post('/check-auth-code', { email: this.email, code: this.code })
        .then(data => {
          if (data.data.token) {
            clearInterval(this.timerCounter)
            localStorageService.setObject('token', data.data.token)
            localStorageService.setObject('partnerEmail', this.email)
            localStorageService.setObject(
              'refresh_token',
              data.data.refresh_token,
            )
            this.$router.push({
              name: 'Home-agence',
              params: {
                partner_uid: this.$route.params.partner_uid,
              },
            })
          } else {
            this.errors = ['Code erroné']
          }
        })
    },
    newCode() {
      this.email = ''
      this.code = ''
      this.step = 1
      this.errors = []
      this.authenticated = false
      if (this.timerCounter !== null) {
        clearInterval(this.timerCounter)
      }
    },
    formatTime(timer) {
      return (
        (timer - (timer % 60)) / 60 +
        ' : ' +
        (timer % 60 < 10 ? '0' : '') +
        (timer % 60)
      )
    },
    formatCode() {
      // Supprime les espaces existants et ajoute des espaces tous les 4 caractères
      this.code = this.code.replace(/\s/g, '').replace(/(.{1})/g, '$1 ')
    },
  },

  mounted() {
    var uuid = this.$route.params.partner_uid
    this.stillLogged = true
    const refresh_token = localStorageService.getValue('refresh_token')

    if (refresh_token !== null && refresh_token !== undefined) {
      requestService
        .post('/token/refresh', { refresh_token: refresh_token })
        .then(data => {
          if (data.data.token) {
            localStorageService.setObject('token', data.data.token)
            localStorageService.setObject(
              'refresh_token',
              data.data.refresh_token,
            )
            this.$router.push({
              name: 'Home-agence',
              params: {
                partner_uid: this.$route.params.partner_uid,
              },
            })
            uuid = null
            this.stillLogged = true
          } else {
            this.stillLogged = false
            uuid = this.$route.params.partner_uid
            localStorageService.clearAll()
          }
        })
    } else {
      this.stillLogged = false
      uuid = this.$route.params.partner_uid
      localStorageService.clearAll()
    }

    if (!this.stillLogged) {
      if (uuid !== undefined && uuid !== null) {
        requestService.get('/partner/uuid/' + uuid).then(data => {
          if (data.data.success) {
            this.company = data.data.company
            this.partnerPlan = data.data.partnerPlan
            this.partnerAdmin = data.data.partnerAdmin
            if (data.data.logo !== null) this.icon = data.data.logo
            localStorageService.setObject(
              'logo',
              data.data.logo !== null ? data.data.logo : undefined,
            )
            localStorageService.setObject(
              'primaryColor',
              data.data.primaryColor !== null
                ? data.data.primaryColor
                : undefined,
            )
            localStorageService.setObject(
              'secondaryColor',
              data.data.secondaryColor !== null
                ? data.data.secondaryColor
                : undefined,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BACKGROUND_DESKTOP',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_BACKGROUND_DESKTOP'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_BACKGROUND_DESKTOP']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BACKGROUND_MOBILE',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_BACKGROUND_MOBILE'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_BACKGROUND_MOBILE']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_INPUT_TEXT_COLOR',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_INPUT_TEXT_COLOR'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_INPUT_TEXT_COLOR']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_LOGO_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_LOGO_2'] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_LOGO_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_LOGO_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_LOGO_1'] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_LOGO_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_ICON_QRCODE_SCREEN',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_ICON_QRCODE_SCREEN'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_ICON_QRCODE_SCREEN']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_CARD_HOME',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_CARD_HOME'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_CARD_HOME']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_ICON_COLOR_INFO',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_INFO'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_INFO']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_ICON_COLOR_WARNING',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_ICON_COLOR_WARNING'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_WARNING']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_1'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_2'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_3',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_3'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_3']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_4',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_4'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_4']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_5',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_5'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_5']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_TEXT_COLOR_ETAPES',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_TEXT_COLOR_ETAPES'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_ETAPES']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_COLOR_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_1'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_COLOR_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_2'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_TEXT_COLOR_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_1'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_TEXT_COLOR_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_2'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_TEXT_COLOR_3',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_3'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_3']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_TEXT_COLOR_4',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_4'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_4']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_BUTTON_TEXT_COLOR_1'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_BUTTON_TEXT_COLOR_1'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_TEXT_COLOR_2',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_BUTTON_TEXT_COLOR_2'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_BUTTON_TEXT_COLOR_2'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FONT_FAMILY_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_1'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FONT_FAMILY_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_2'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_HAIR',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_HAIR'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_HAIR']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_SKIRT',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_SKIRT'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_SKIRT']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_GIRL_TOP',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_GIRL_TOP'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_GIRL_TOP']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FONT_FAMILY_3',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_3'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_3']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FONT_SIZE_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_1'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FONT_SIZE_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_2'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FONT_SIZE_3',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_3'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_3']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_FONT_SIZE',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_BUTTON_FONT_SIZE'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_BUTTON_FONT_SIZE']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARD_BORDER_ROUND',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_CARD_BORDER_ROUND'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARD_BORDER_ROUND']
                : null,
            )
            localStorageService.setObject(
              'validatedImages',
              data.data.validatedImages,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_BORDER_ROUND',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_BUTTON_BORDER_ROUND'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_BUTTON_BORDER_ROUND'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_QRCODE_BORDER_COLOR',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_QRCODE_BORDER_COLOR'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_QRCODE_BORDER_COLOR'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_SHADOW_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_BUTTON_SHADOW_1'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_BUTTON_SHADOW_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_SHADOW_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_BUTTON_SHADOW_2'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_BUTTON_SHADOW_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_QRCODE_SHADOW',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_QRCODE_SHADOW'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_QRCODE_SHADOW']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARD_SHADOW_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_1'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARD_SHADOW_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_2'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARD_SHADOW_3',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_3'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_3']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_ICON_COLOR_INFO_2',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_ICON_COLOR_INFO_2'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_INFO_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_ICON_COLOR_WARNING_2',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_ICON_COLOR_WARNING_2'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_ICON_COLOR_WARNING_2'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARTE',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_CARTE'] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARTE']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_1_FILL_ICON',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_COLOR_1_FILL_ICON'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_1_FILL_ICON']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_2_FILL_ICON',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_COLOR_2_FILL_ICON'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_2_FILL_ICON']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_ALLOW_WALLET',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_ALLOW_WALLET'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_ALLOW_WALLET']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARD_COLOR',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_CARD_COLOR'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARD_COLOR']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARD_BO_COLOR',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_CARD_BO_COLOR'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARD_BO_COLOR']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_DRAWER_COLOR',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_DRAWER_COLOR'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_DRAWER_COLOR']
                : null,
            )

            localStorageService.setObject(
              'shortliveApiKey',
              this.$route.params.id,
            )

            localStorageService.setObject(
              'documentId',
              data.data.documentId !== null ? data.data.documentId : undefined,
            )
            localStorageService.setObject(
              'logo',
              data.data.logo !== null ? data.data.logo : undefined,
            )
            localStorageService.setObject(
              'primaryColor',
              data.data.primaryColor !== null
                ? data.data.primaryColor
                : undefined,
            )
            localStorageService.setObject(
              'secondaryColor',
              data.data.secondaryColor !== null
                ? data.data.secondaryColor
                : undefined,
            )

            localStorageService.setObject(
              'WEBAPPPRO_BACKGROUND_DESKTOP',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_BACKGROUND_DESKTOP'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_BACKGROUND_DESKTOP']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BACKGROUND_MOBILE',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_BACKGROUND_MOBILE'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_BACKGROUND_MOBILE']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_INPUT_TEXT_COLOR',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_INPUT_TEXT_COLOR'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_INPUT_TEXT_COLOR']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_LOGO_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_LOGO_2'] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_LOGO_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_LOGO_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_LOGO_1'] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_LOGO_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_ICON_QRCODE_SCREEN',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_ICON_QRCODE_SCREEN'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_ICON_QRCODE_SCREEN']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_1'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_2'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_3',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_3'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_3']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_4',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_4'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_4']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_5',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_5'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_5']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARD_COLOR',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_CARD_COLOR'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARD_COLOR']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_COLOR_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_1'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_TEXT_COLOR_ETAPES',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_TEXT_COLOR_ETAPES'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_ETAPES']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_COLOR_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_2'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FONT_FAMILY',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_TEXT_COLOR_3',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_3'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_3']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_TEXT_COLOR_4',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_4'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_4']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FONT_SIZE_1',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_1'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_1']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FONT_SIZE_2',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_2'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_2']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FONT_SIZE_3',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_3'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_3']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_BUTTON_FONT_SIZE',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_BUTTON_FONT_SIZE'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_BUTTON_FONT_SIZE']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARD_BORDER_ROUND',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_CARD_BORDER_ROUND'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARD_BORDER_ROUND']
                : null,
            )
            localStorageService.setObject(
              'validatedImages',
              data.data.validatedImages,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARTE',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_CARTE'] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARTE']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_1_FILL_ICON',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_COLOR_1_FILL_ICON'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_1_FILL_ICON']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_2_FILL_ICON',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_COLOR_2_FILL_ICON'
                ] !== undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_2_FILL_ICON']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_CARD_HOME',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_CARD_HOME'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_CARD_HOME']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_HAIR',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_HAIR'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_HAIR']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_SKIRT',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_SKIRT'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_SKIRT']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_COLOR_GIRL_TOP',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_COLOR_GIRL_TOP'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_COLOR_GIRL_TOP']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT',
              data.data.technicalInformation &&
                data.data.technicalInformation[
                  'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT'
                ] !== undefined
                ? data.data.technicalInformation[
                    'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT'
                  ]
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_ALLOW_WALLET',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_ALLOW_WALLET'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_ALLOW_WALLET']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_CARD_BO_COLOR',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_CARD_BO_COLOR'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_CARD_BO_COLOR']
                : null,
            )
            localStorageService.setObject(
              'WEBAPPPRO_DRAWER_COLOR',
              data.data.technicalInformation &&
                data.data.technicalInformation['WEBAPPPRO_DRAWER_COLOR'] !==
                  undefined
                ? data.data.technicalInformation['WEBAPPPRO_DRAWER_COLOR']
                : null,
            )
          } else {
            // partner non reconnu
            this.$router.push({
              name: 'NotAuthorizedPartner',
              params: {
                partner_uid: this.$route.params.partner_uid,
              },
            })
          }
        })
      }
    }
  },
}
</script>
<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}

a {
  color: black;
}
.background-container {
  /*background-size: cover;*/
  height: 100vh !important; /* Prend toute la hauteur de l'écran */
  width: 100vw !important; /* Prend toute la largeur de l'écran */
  display: flex;
}
.code-inputs {
  display: flex;
}
html,
body {
  height: 100% !important;
  margin: 0 !important;
  overflow: hidden !important; /* Désactive le défilement vertical et horizontal sur le corps et l'html */
}

.v-card {
  border-radius: 0px 25px 25px 0px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
input {
  width: 230px;
  text-align: center;
  font-size: 1.5em;
  letter-spacing: 1em; /* Adjust this value to increase spacing */
}
</style>
