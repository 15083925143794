<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_desktop) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div>
      <div :rail="rail" permanent class="accueil-drawer">
        <Accueil></Accueil>
      </div>

      <div
        class="d-flex flex-column justify-center align-center"
        style="margin-top: 5%"
      >
        <v-img
          v-if="WEBAPPPRO_LOGO_2"
          :src="require(`../../assets/images/${WEBAPPPRO_LOGO_2}`)"
          width="219px"
          style="margin-top: 112px"
          class="mx-auto mt-12"
        ></v-img>
        <v-div
          class="mb-5 d-flex justify-center align-center"
          style="margin-top: 100px"
        >
          <v-card
            v-if="WEBAPPPRO_ALLOW_WALLET == 1"
            width="214"
            height="186"
            :style="{
              backgroundColor: WEBAPPPRO_CARD_BO_COLOR,
              boxShadow: WEBAPPPRO_CARD_SHADOW_1,
              borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
            }"
            class="mt-10 mx-10"
          >
            <v-btn
              class="my-5 d-flex btn"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
              "
              @click="showWalletCharge"
            >
            </v-btn>
            <div class="pt-5 px-5" height="45px">
              <svg
                width="40"
                height="33"
                viewBox="0 0 40 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M33.7389 8.13526H33.44V6.43807C33.44 2.88782 30.6358 0 27.1832 0H5.21685C2.34105 -4.32957e-08 0 2.40724 0 5.36866V26.5619C0 30.1122 2.80844 33 6.26105 33H33.7389C37.1916 33 40 30.1122 40 26.5619V14.5733C40 11.0231 37.1916 8.13526 33.7389 8.13526ZM5.21685 2.59774H27.1832C29.2421 2.59774 30.9137 4.32091 30.9137 6.43807V8.13526H5.21685C4.49684 8.13526 3.82317 7.84952 3.31368 7.32563C2.80422 6.80608 2.52632 6.10902 2.52632 5.36866C2.52632 3.84033 3.73473 2.59774 5.21685 2.59774ZM37.4737 23.4446H34.6779C33.1368 23.4446 31.8821 22.1544 31.8821 20.5655C31.8821 18.9808 33.1368 17.6906 34.6779 17.6906H37.4737V23.4446ZM37.4737 15.0929H34.6779C31.7432 15.0929 29.3558 17.5477 29.3558 20.5655C29.3558 23.5875 31.7432 26.0424 34.6779 26.0424H37.4737V26.5619C37.4737 28.6791 35.7979 30.4023 33.7389 30.4023H6.26105C4.20212 30.4023 2.52632 28.6791 2.52632 26.5619V9.97099C3.33054 10.4689 4.25265 10.733 5.21685 10.733H33.7389C35.7979 10.733 37.4737 12.4562 37.4737 14.5733V15.0929Z"
                  fill="white"
                />
              </svg>
            </div>
            <v-card-text
              :style="{
                color: buttonTextValidColor,
                paddingRight: '25px',
                paddingLeft: '35px',
              }"
            >
              <div class="d-flex flex-row mt-3">
                <p
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_1,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_1,
                    paddingRight: '15px',
                  }"
                >
                  1
                </p>
                <p
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  }"
                >
                  Alimentez votre porte-monnaie
                </p>
              </div>
            </v-card-text>
          </v-card>
          <v-card
            :style="{
              backgroundColor: WEBAPPPRO_CARD_BO_COLOR,
              boxShadow: WEBAPPPRO_CARD_SHADOW_1,
              borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
            }"
            width="214"
            height="186"
            class="mt-10 mx-10"
          >
            <v-btn
              class="my-5 d-flex btn"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
              "
              @click="newShoot"
            >
            </v-btn>
            <div class="pt-5 px-5" height="45px">
              <v-img
                width="40"
                height="33"
                src="../../assets/images/Group_49.png"
                alt=""
                class="mx-3"
              />
            </div>
            <v-card-text
              :style="{
                color: buttonTextValidColor,
                paddingRight: '25px',
                paddingLeft: '35px',
              }"
            >
              <div class="d-flex flex-row mt-3">
                <p
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_1,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_1,
                    paddingRight: '15px',
                  }"
                >
                  {{ cardNumbers[0] }}
                </p>

                <p
                  class="flex-grow-1"
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  }"
                >
                  Demandez à votre candidat de faire sa photo
                </p>
              </div>
            </v-card-text>
          </v-card>
          <v-card
            :style="{
              backgroundColor: WEBAPPPRO_CARD_BO_COLOR,
              boxShadow: WEBAPPPRO_CARD_SHADOW_1,
              borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
            }"
            width="214"
            height="186"
            class="mt-10 mx-10"
          >
            <v-btn
              class="my-5 d-flex btn"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
              "
              @click="showWaittingShootList"
            >
            </v-btn>
            <div class="pt-5 px-5" height="45px">
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 0C7.425 0 0 7.425 0 16.5C0 17.655 0.165 18.975 0.33 20.13C0.495 20.955 1.155 21.45 1.98 21.45C2.145 21.45 2.145 21.45 2.31 21.45C3.135 21.285 3.795 20.295 3.63 19.47C3.465 18.48 3.3 17.49 3.3 16.5C3.3 9.24 9.24 3.3 16.5 3.3C23.76 3.3 29.7 9.24 29.7 16.5C29.7 23.76 23.76 29.7 16.5 29.7C15.51 29.7 14.52 29.535 13.53 29.37C12.705 29.205 11.88 29.7 11.55 30.69C11.22 31.68 11.88 32.505 12.87 32.67C14.025 33 15.345 33 16.5 33C25.575 33 33 25.575 33 16.5C33 7.425 25.575 0 16.5 0Z"
                  fill="white"
                />
                <path
                  d="M14.85 9.8999C13.86 9.8999 13.2 10.5599 13.2 11.5499V18.1499C13.2 19.1399 13.86 19.7999 14.85 19.7999H21.45C22.44 19.7999 23.1 19.1399 23.1 18.1499C23.1 17.1599 22.44 16.4999 21.45 16.4999H16.5V11.5499C16.5 10.5599 15.84 9.8999 14.85 9.8999Z"
                  fill="white"
                />
                <path
                  d="M5.61005 23.9253C5.11505 23.1003 4.12505 22.9353 3.30005 23.4303C2.47505 23.9253 2.31005 24.9153 2.80505 25.7403C3.96005 27.3903 5.44505 28.8753 7.09505 30.0303C7.42505 30.1953 7.75505 30.3603 8.08505 30.3603C8.58005 30.3603 9.07505 30.0303 9.40505 29.7003C9.90005 28.8753 9.73505 27.8853 8.91005 27.3903C7.75505 26.4003 6.60005 25.2453 5.61005 23.9253Z"
                  fill="white"
                />
              </svg>
            </div>
            <v-card-text
              :style="{
                color: buttonTextValidColor,
                paddingRight: '25px',
                paddingLeft: '35px',
              }"
            >
              <div class="d-flex flex-row mt-3">
                <p
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_1,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_1,
                    fontWeight: '700px',
                    paddingRight: '15px',
                  }"
                >
                  {{ cardNumbers[1] }}
                </p>
                <p
                  class="flex-grow-1"
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  }"
                >
                  Prises de photo en attente
                </p>
              </div>
            </v-card-text>
          </v-card>
          <v-card
            :style="{
              backgroundColor: WEBAPPPRO_CARD_BO_COLOR,
              boxShadow: WEBAPPPRO_CARD_SHADOW_1,
              borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
            }"
            width="214"
            height="186"
            class="mt-10 mx-10"
          >
            <v-btn
              class="my-5 d-flex btn"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
              "
              @click="showOrderList"
            >
            </v-btn>
            <div class="pt-5 px-5" height="45px">
              <svg
                width="36"
                height="40"
                viewBox="0 0 36 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.5959 38.9042H9.05593C5.53786 38.9042 2.64867 36.2617 2.3354 32.7572L1.01412 17.9645C0.924894 16.967 1.26154 15.9707 1.93757 15.2319C2.6136 14.4928 3.57562 14.0688 4.57739 14.0688H32.0744C33.0762 14.0688 34.0382 14.4928 34.7142 15.2319C35.3902 15.9707 35.7269 16.967 35.6377 17.9645L34.3164 32.7572C34.0031 36.2617 31.1139 38.9042 27.5959 38.9042ZM4.57739 16.0638C4.12775 16.0638 3.71317 16.2466 3.40964 16.5781C3.1065 16.9101 2.96116 17.3391 3.00129 17.7868L4.32257 32.5795C4.5431 35.0479 6.57781 36.9092 9.05593 36.9092H27.5959C30.074 36.9092 32.1087 35.0479 32.3292 32.5795L33.6505 17.7868C33.6906 17.3391 33.5453 16.9101 33.2421 16.5781C32.9386 16.2466 32.524 16.0638 32.0744 16.0638H4.57739Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.6"
                />
                <path
                  d="M26.73 21.7355C26.1791 21.7355 25.7325 21.289 25.7325 20.7381V10.0627C25.7325 6.16548 22.562 2.99496 18.6648 2.99496H17.9869C14.0897 2.99496 10.9192 6.16548 10.9192 10.0627V20.7381C10.9192 21.289 10.4726 21.7355 9.92168 21.7355C9.37072 21.7355 8.92419 21.289 8.92419 20.7381V10.0627C8.92419 5.06552 12.9897 1 17.9869 1H18.6648C23.662 1 27.7275 5.06552 27.7275 10.0627V20.7381C27.7275 21.289 27.281 21.7355 26.73 21.7355Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.6"
                />
                <path
                  d="M29.1171 21.7352H24.3432C23.7922 21.7352 23.3457 21.2887 23.3457 20.7377C23.3457 20.1868 23.7922 19.7402 24.3432 19.7402H29.1171C29.668 19.7402 30.1146 20.1868 30.1146 20.7377C30.1146 21.2887 29.668 21.7352 29.1171 21.7352Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.6"
                />
                <path
                  d="M12.3087 21.7352H7.53484C6.98388 21.7352 6.53735 21.2887 6.53735 20.7377C6.53735 20.1868 6.98388 19.7402 7.53484 19.7402H12.3087C12.8597 19.7402 13.3062 20.1868 13.3062 20.7377C13.3062 21.2887 12.8597 21.7352 12.3087 21.7352Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.6"
                />
              </svg>
            </div>
            <v-card-text
              :style="{
                color: buttonTextValidColor,
                paddingRight: '25px',
                paddingLeft: '35px',
              }"
            >
              <div class="d-flex flex-row mt-3">
                <p
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_1,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_1,
                    fontWeight: '700px',
                    paddingRight: '15px',
                  }"
                >
                  {{ cardNumbers[2] }}
                </p>
                <p
                  class="flex-grow-1"
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  }"
                >
                  Prises de photo réalisées
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-div>
        <v-card
          v-if="WEBAPPPRO_ALLOW_WALLET == 1"
          style="width: 384px; height: 73px; margin-top: 100px"
          class="text-center d-flex align-center justify-center"
          :color="buttonNeutralColor"
          :style="{ borderRadius: WEBAPPPRO_CARD_BORDER_ROUND }"
        >
          <v-card-text
            :style="styleTitle"
            class="d-flex align-center justify-center"
          >
            <span
              :style="{
                color: WEBAPPPRO_COLOR_5,
                fontSize: WEBAPPPRO_FONT_SIZE_1,
                fontFamily: WEBAPPPRO_FONT_FAMILY_1,
                fontWeight: '700px',
              }"
              >{{ printCredit() }}</span
            >
            <p
              :style="{
                color: WEBAPPPRO_TEXT_COLOR_2,
                fontSize: WEBAPPPRO_FONT_SIZE_3,
                fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                fontWeight: '400px',
                lineHeight: '20px',
                marginTop: '20px',
              }"
              class="ml-2"
            >
              code(s) ePhoto restant(s)
            </p>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
import Accueil from './Accueil.vue'
export default {
  components: {
    Accueil,
  },
  data() {
    return {
      isVisible: false,
      agenceUuid: null,
      agenceId: null,
      credit: 0,
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      WEBAPPPRO_COLOR_5: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_5',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_CARD_BO_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BO_COLOR',
      ),
      WEBAPPPRO_LOGO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_2',
      ),
      background_desktop: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_DESKTOP',
      ),
      WEBAPPPRO_ALLOW_WALLET: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ALLOW_WALLET',
      ),
    }
  },
  computed: {
    cardNumbers() {
      if (this.WEBAPPPRO_ALLOW_WALLET == 1) {
        return [2, 3, 4]
      } else {
        return [1, 2, 3]
      }
    },
  },
  methods: {
    showWalletCharge() {
      this.$router.push({
        name: 'Wallet-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    showWaittingShootList() {
      this.$router.push({
        name: 'Waiting-orders-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    showOrderList() {
      this.$router.push({
        name: 'Orders-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    newShoot() {
      this.$router.push({
        name: 'New-order-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
          agence_id: localStorageService.getValue('agenceId'),
          agence_uid: localStorageService.getValue('agenceUuid'),
        },
      })
    },
    logout() {
      localStorageService.clearAll()
      this.$router.push({
        name: 'Authentication-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    printCredit() {
      if (this.credit > 1) {
        return this.credit
      } else {
        return this.credit
      }
    },
  },
  mounted() {
    var uuid = this.$route.params.partner_uid
    if (uuid !== null) {
      requestService.get('/partner/info/' + uuid).then(response => {
        if (response.data) {
          this.credit = response.data.credit
          this.agenceUuid = response.data.partner_uuid
          this.agenceId = response.data.partner_id

          localStorageService.setObject(
            'partner_uid',
            this.$route.params.partner_uid,
          )
          localStorageService.setObject(
            'agenceUuid',
            response.data.partner_uuid,
          )
          localStorageService.setObject('agenceId', response.data.partner_id)
        }
      })
    } else {
      this.$router.push({
        name: 'NotAuthorizedPartner',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    }
  },
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
.btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.custom-img {
  margin-top: 15px;
}
.background-container {
  display: flex;
  flex-direction: column;
}
</style>
