<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_mobile) + ')',
      height: '100vh',
      width: '100vw',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div class="d-flex flex-column overflow-y-auto">
      <div class="content-container">
        <h1
          class="text-center mx-5"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_1,
            fontSize: WEBAPPPRO_FONT_SIZE_1,
            fontFamily: WEBAPPPRO_FONT_FAMILY_1,
            marginBottom: '10px',
            fontWeight: 'regular',
            'text-transform': 'uppercase',
          }"
        >
          Votre commande est en cours de traitement
        </h1>
        <div class="justify-center">
          <v-card
            width="327"
            :style="{
              borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
              backgroundColor: WEBAPPPRO_COLOR_2,
              boxShadow: WEBAPPPRO_CARD_SHADOW_2,
              marginBottom: '30px',
            }"
            :color="color"
            :variant="variant"
            class="pa-5 mx-auto"
          >
            <v-row align="center">
              <v-card-item>
                <v-card-title
                  ><v-row align="center">
                    <v-col cols="auto"
                      ><svg
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="34"
                          height="34"
                          rx="8"
                          :fill="WEBAPPPRO_ICON_COLOR_INFO"
                        />
                        <path
                          d="M21.2187 14.5777H24.7655C25.1776 14.5777 25.5 14.2369 25.5 13.8423V10.2733C25.5 9.86081 25.1596 9.53798 24.7655 9.53798C24.3714 9.53798 24.0311 9.87875 24.0311 10.2733V12.0309L22.9742 10.9728C19.6781 7.67276 14.2861 7.67276 10.99 10.9907C9.37777 12.5869 8.5 14.7212 8.5 16.9989C8.5 19.2766 9.37777 21.3929 10.99 23.0071C12.5843 24.6033 14.716 25.5 16.991 25.5C19.2661 25.5 21.3799 24.6212 22.9921 23.0071C24.2998 21.6978 25.1417 20.0299 25.3925 18.1826C25.4463 17.7701 25.1596 17.3934 24.7655 17.3396C24.3535 17.2679 23.9773 17.5728 23.9236 17.9674C23.7086 19.4918 23.0279 20.8549 21.9352 21.9489C19.284 24.6033 14.6802 24.6033 12.029 21.9489C10.7034 20.6217 9.98683 18.8641 9.98683 16.9989C9.98683 15.1337 10.7213 13.3581 12.029 12.0489C14.7518 9.32277 19.1944 9.32277 21.9352 12.0489L22.9921 13.107H21.2366C20.8246 13.107 20.5021 13.4478 20.5021 13.8423C20.5021 14.2369 20.8066 14.5777 21.2187 14.5777Z"
                          :fill="WEBAPPPRO_ICON_COLOR_INFO_2"
                          stroke="white"
                          stroke-width="0.8"
                        />
                      </svg>
                    </v-col>
                    <v-col
                      cols="auto"
                      :style="{
                        color: WEBAPPPRO_TEXT_COLOR_2,
                        fontSize: WEBAPPPRO_FONT_SIZE_2,
                        fontFamily: WEBAPPPRO_FONT_FAMILY_2,
                        fontWeight: 'bold',
                        marginTop: '-9px',
                        'text-transform': 'uppercase',
                      }"
                      >Traitement</v-col
                    >
                  </v-row>
                </v-card-title>
                <div
                  class="pl-5"
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                    marginRight: '20px',
                    fontWeight: 'bold',
                  }"
                >
                  Votre commande sera analysée par nos équipes.
                </div>
                <div
                  class="pl-5 mb-5"
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                    marginTop: '20px',
                    marginRight: '20px',
                  }"
                >
                  Si votre photo ou signature risque d’être refusée par
                  l’administration, vous serez invité, par email ou par votre
                  agence à refaire votre photo ou signature
                  <span style="font-weight: bold">gratuitement</span>.
                </div>
              </v-card-item>
            </v-row>
          </v-card>
          <v-card
            width="327"
            :style="{
              borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
              backgroundColor: WEBAPPPRO_COLOR_2,
              boxShadow: WEBAPPPRO_CARD_SHADOW_2,
              marginBottom: '30px',
            }"
            :color="color"
            :variant="variant"
            class="pa-5 mx-auto"
          >
            <v-row align="center">
              <v-card-item>
                <v-card-title>
                  <v-row align="center">
                    <v-col cols="auto"
                      ><svg
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="34"
                          height="34"
                          rx="8"
                          :fill="WEBAPPPRO_ICON_COLOR_INFO"
                        />
                        <path
                          d="M22.8051 11.1157C22.5075 11.4286 22.213 11.7382 21.9153 12.0512C21.2028 12.8003 20.4903 13.5494 19.7778 14.2984C18.9165 15.204 18.0583 16.1062 17.1969 17.0118C16.4591 17.7875 15.7181 18.5665 14.9803 19.3422C14.6224 19.7184 14.2583 20.0913 13.9068 20.4708C13.8909 20.4875 13.8814 20.4941 13.8624 20.5108C13.9701 20.4242 14.0746 20.3377 14.1823 20.2511C14.1696 20.2578 14.1601 20.2611 14.1474 20.2678C14.2741 20.2112 14.4008 20.1546 14.5243 20.1013C14.5116 20.1046 14.4989 20.1046 14.4863 20.108C14.6256 20.088 14.7681 20.068 14.9074 20.048C14.8948 20.048 14.8821 20.048 14.8694 20.048C15.0088 20.068 15.1513 20.088 15.2906 20.108C15.2779 20.1046 15.2653 20.1013 15.2526 20.098C15.3793 20.1546 15.5059 20.2112 15.6294 20.2644C15.6168 20.2578 15.6073 20.2511 15.5946 20.2478C15.7023 20.3343 15.8068 20.4209 15.9144 20.5075C15.6453 20.2811 15.4141 19.9881 15.1703 19.7318C14.7301 19.269 14.2899 18.8062 13.8498 18.3435C13.3114 17.7775 12.7762 17.2148 12.2379 16.6489C11.8927 16.286 11.5476 15.9231 11.2056 15.5635C10.9174 15.2606 10.4931 15.0775 10.0846 15.0775C9.69506 15.0775 9.23272 15.2573 8.96355 15.5635C8.68805 15.8798 8.48222 16.296 8.50122 16.7421C8.52022 17.1849 8.66272 17.601 8.96355 17.9206C9.77739 18.7763 10.5944 19.6352 11.4082 20.4908C12.0226 21.1367 12.6369 21.7826 13.2512 22.4284C13.4824 22.6715 13.7104 22.9478 13.9923 23.1242C14.6858 23.557 15.5661 23.4106 16.1329 22.8313C16.1583 22.808 16.1804 22.7813 16.2058 22.7547C17.0544 21.8625 17.9031 20.9702 18.7518 20.078C20.0533 18.7097 21.3516 17.3447 22.6531 15.9764C23.4353 15.154 24.227 14.3384 25.0028 13.5061C25.0155 13.4928 25.025 13.4828 25.0377 13.4694C25.3258 13.1665 25.5 12.7204 25.5 12.2909C25.5 11.8814 25.329 11.3953 25.0377 11.1123C24.7368 10.8227 24.341 10.6063 23.9167 10.6263C23.505 10.6496 23.106 10.7961 22.8051 11.1157Z"
                          :fill="WEBAPPPRO_ICON_COLOR_INFO_2"
                        />
                      </svg>
                    </v-col>
                    <v-col
                      cols="auto"
                      :style="{
                        color: WEBAPPPRO_TEXT_COLOR_2,
                        fontSize: WEBAPPPRO_FONT_SIZE_2,
                        fontFamily: WEBAPPPRO_FONT_FAMILY_2,
                        fontWeight: 'bold',
                        marginTop: '-9px',
                        'text-transform': 'uppercase',
                        'letter-spacing': '0em',
                      }"
                      >Validation</v-col
                    >
                  </v-row>
                </v-card-title>
                <div
                  class="pl-5 mb-5"
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                    marginTop: '5px',
                    marginRight: '20px',
                    fontWeight: 'bold',
                  }"
                >
                  Une fois votre commande validée, un code ePhoto sera envoyé à
                  votre agence.
                  <span style="font-weight: normal"
                    >Merci pour votre confiance!</span
                  >
                </div>
              </v-card-item>
            </v-row>
          </v-card>
        </div>
      </div>
      <br />
      <!--</v-main>-->
    </div>
  </div>
</template>
<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
//import Accueil from './Accueil.vue'

export default {
  name: 'Final',
  /*components: {
    Accueil,
  },*/
  data() {
    return {
      confEmail: '',
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      errors: [],
      agenceName: '',
      externalId: '',

      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      color1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      isClicked: false,
      WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_1',
      ),
      WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_3',
      ),
      WEBAPPPRO_ICON_COLOR_INFO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO_2',
      ),
      WEBAPPPRO_ICON_COLOR_WARNING_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_WARNING_2',
      ),
    }
  },
  mounted() {
    if (!localStorageService.getValue('shortliveApiKey')) {
      this.$router.push({ name: 'NotAuthorized' })
      return
    }
    const uuid = localStorageService.getValue('partner_uid')
    if (
      uuid !== undefined &&
      uuid !== null &&
      !localStorageService.getValue('logo')
    ) {
      requestService.get('/partner/uuid/' + uuid).then(response => {
        if (response.data.success) {
          this.agenceName = response.data.name
          if (response.data.logo !== null) this.icon = response.data.logo
          localStorageService.setObject(
            'logo',
            response.data.logo !== null ? response.data.logo : undefined,
          )
          localStorageService.setObject(
            'primaryColor',
            response.data.primaryColor !== null
              ? response.data.primaryColor
              : undefined,
          )
          localStorageService.setObject(
            'secondaryColor',
            response.data.secondaryColor !== null
              ? response.data.secondaryColor
              : undefined,
          )
        }
      })
    }
    localStorageService.clearAll()
  },
}
</script>
<style>
.rounded-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 0px;
  background-color: #ffffff;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.photo-icon {
  margin: auto;
  width: 17px;
  height: 12px;
}
.text-align-left {
  display: inline-block;
  vertical-align: middle;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
</style>
